<template>
  <div>
    <template v-if="items && items.length">
      <div class="row mini-gallery-container">
        <div class="mini-gallery">
          <div
            v-for="(item, index) in items"
            v-bind:key="index"
            v-tooltip.bottom="item.title"
            v-bind:class="['mini-gallery__item', getItemClass(item)]"
          >
            <span>{{ getFirstLetter(item) }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "TableMarketplaceList",
  props: {
    items: {
      default: () => [],
    },
  },
  methods: {
    getFirstLetter(item) {
      return item.title.charAt(0).toUpperCase();
    },
    getItemClass(item) {
      const itemClass = {
        n11: "alert-danger",
        hepsiburada: "alert-warning",
        trendyol: "alert-orange",
        gittigidiyor: "alert-success",
        epttavm: "alert-primary",
        amazon: "alert-dark",
        aliexpress: "alert-danger",
        pazarama: "alert-pink",
      };

      if (itemClass.hasOwnProperty(item.name)) {
        return itemClass[item.name];
      } else {
        return "bg-white";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mini-gallery {
  display: flex;
  position: relative;
}
.mini-gallery__item {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transition: 0.4s all;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-weight: 500;
  &:hover {
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
  }
  span {
    user-select: none;
  }
}
.mini-gallery__item {
  position: relative;
  &:hover {
    border: 1px dashed gray;
    z-index: 1;
  }
}
.mini-gallery__item:not(:first-child) {
  margin-left: -10px;
}
.mini-gallery-container {
  position: relative;
}
</style>